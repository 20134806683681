// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publicaciones-investigacion-js": () => import("./../../../src/pages/publicaciones-investigacion.js" /* webpackChunkName: "component---src-pages-publicaciones-investigacion-js" */),
  "component---src-pages-publicaciones-js": () => import("./../../../src/pages/publicaciones.js" /* webpackChunkName: "component---src-pages-publicaciones-js" */),
  "component---src-pages-publicaciones-libros-js": () => import("./../../../src/pages/publicaciones-libros.js" /* webpackChunkName: "component---src-pages-publicaciones-libros-js" */),
  "component---src-pages-publicaciones-prensa-js": () => import("./../../../src/pages/publicaciones-prensa.js" /* webpackChunkName: "component---src-pages-publicaciones-prensa-js" */),
  "component---src-pages-publicaciones-revistas-js": () => import("./../../../src/pages/publicaciones-revistas.js" /* webpackChunkName: "component---src-pages-publicaciones-revistas-js" */),
  "component---src-pages-publicaciones-video-js": () => import("./../../../src/pages/publicaciones-video.js" /* webpackChunkName: "component---src-pages-publicaciones-video-js" */),
  "component---src-templates-publications-js": () => import("./../../../src/templates/publications.js" /* webpackChunkName: "component---src-templates-publications-js" */)
}

